import { useContext } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import {
  Bars3BottomLeftIcon,
  BookOpenIcon,
  ChartPieIcon,
  CheckIcon,
  CogIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline';
import { Resizable } from 're-resizable';

import { useLocalStorage } from '@ftrprf/hooks';

import { ActionStyledAsButton } from '../../buttons/ActionStyledAsButton';
import useFormatMessage from '../../../hooks/useFormatMessage';

import { ProgressBar } from '../../progress/ProgressBar';
import {
  SlideViewerContext,
  SlideViewerLeftAction,
  SlideViewerRightAction,
  SlideViewerSlideNumber,
} from '../../slideviewer/SlideViewer';

import SideBarSettings from './SideBarSettings';
import SideBarSlidesOverview from './SideBarSlidesOverview';
import SideBarTeacherInfo from './SideBarTeacherInfo';
import Tabs from './Tabs';
import { UserGroupInfo } from './UserGroupInfo';
import { ExampleAnswer } from './ExampleAnswer';

const enable = {
  bottom: false,
  bottomLeft: false,
  bottomRight: false,
  left: false,
  right: true,
  top: false,
  topLeft: false,
  topRight: false,
};

const standardWidth = 385;

export default function SideBar({
  currentSlide,
  feedbackUrl,
  lesson,
  showFeedbackDialog,
  showTeacherInfoTab,
  steamsTab,
}) {
  const t = useFormatMessage();

  const [sidebarSize, setSidebarSize] = useLocalStorage(
    'SLIDE_VIEWER_SIDEBAR_SIZE',
    {
      width: standardWidth,
    },
  );

  const SteamsTab = steamsTab;

  const { currentSlideIndex, isCk5, slides, user, userGroupInfo } =
    useContext(SlideViewerContext);
  const { isStudent } = user;

  const solution =
    currentSlide.question?.solution || currentSlide?.questions?.[0]?.solution;
  const showSolution = solution && !isStudent;

  return (
    <Resizable
      className="border-r border-gray-300 overflow-hidden flex flex-col self-stretch bg-white z-10"
      data-test="slideviewer-sidebar"
      defaultSize={sidebarSize}
      enable={enable}
      maxWidth="50%"
      minWidth={standardWidth}
      onResizeStop={(e, direction, ref, d) => {
        setSidebarSize((s) => ({
          width: s.width + d.width,
        }));
      }}
    >
      <div className="flex justify-between pt-6 px-4">
        <h2 className="text-2xl font-bold leading-none">{lesson?.title}</h2>
        <div className="flex flex-shrink-0 items-center text-2xl">
          <SlideViewerLeftAction className="text-2xl">
            <FaChevronLeft />
          </SlideViewerLeftAction>
          <SlideViewerRightAction className="text-2xl">
            <FaChevronRight />
          </SlideViewerRightAction>
        </div>
      </div>

      <ProgressBar
        black
        className="mx-4 mt-5"
        maximumProgress={slides.length - 1}
        progress={currentSlideIndex}
      />

      <Tabs
        containerClassName="flex flex-col overflow-hidden flex-grow"
        footerContainerClassName="flex border-t border-gray-300 px-4 pt-2 pb-1 justify-between items-center"
        footerExtra={
          <div className="flex items-center">
            <div className="mr-4">
              {feedbackUrl && !user?.isStudent && (
                <div className="mb-2">
                  <ActionStyledAsButton
                    onClick={showFeedbackDialog}
                    secondary
                    test="feedbackButton"
                  >
                    {t('styled-slide-viewer.settings.feedback')}
                  </ActionStyledAsButton>
                </div>
              )}
            </div>
            <div className="text-xs">
              <SlideViewerSlideNumber />
            </div>
          </div>
        }
        labelClassName="px-2 mr-4 pb-px border-b-2 border-transparent opacity-50"
        labelClassNameActive="px-2 pb-px mr-4 border-b-2 border-accent-500"
        labelsContainerClassName="flex"
      >
        {showTeacherInfoTab && (
          <div
            label={
              <button
                aria-label={t('styled-slide-viewer.teacher_manual')}
                type="button"
              >
                <BookOpenIcon className="w-6 h-6" />
              </button>
            }
          >
            <SideBarTeacherInfo currentSlide={currentSlide} />
          </div>
        )}

        <div
          label={
            <button
              aria-label={t('styled-slide-viewer.table_of_contents')}
              type="button"
            >
              <Bars3BottomLeftIcon className="w-6 h-6" />
            </button>
          }
        >
          <SideBarSlidesOverview />
        </div>

        {showSolution && (
          <div
            label={
              <button type="button">
                <span className="sr-only">
                  {t('styled-slide-viewer.example_answer')}
                </span>
                <CheckIcon className="w-6 h-6" />
              </button>
            }
          >
            <ExampleAnswer solution={solution} />
          </div>
        )}

        {!isCk5 && (
          <div
            label={
              <button
                aria-label={t('styled-slide-viewer.settings')}
                type="button"
              >
                <CogIcon className="w-6 h-6" />
              </button>
            }
          >
            <SideBarSettings />
          </div>
        )}
        {userGroupInfo && (
          <div
            label={
              <button
                aria-label={t('styled-slide-viewer.userGroupInfo')}
                type="button"
              >
                <UserGroupIcon className="w-6 h-6" />
              </button>
            }
          >
            <UserGroupInfo userGroupInfo={userGroupInfo} />
          </div>
        )}

        {!!steamsTab && (
          <div
            label={
              <button
                aria-label={t('styled-slide-viewer.steams')}
                type="button"
              >
                <ChartPieIcon className="w-6 h-6" />
              </button>
            }
          >
            <SteamsTab />
          </div>
        )}
      </Tabs>
    </Resizable>
  );
}
